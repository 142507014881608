import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
  FaDiscord,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
/*   {
    thumb: openseaIcon,
    url: "#",
  }, */
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/socialkongs",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/socialkongs/",
  },
  {
    icon: <FaDiscord />,
    url: "https://discord.gg/vpRKtCneQG",
  },
];

export default data;
