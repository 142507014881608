const authorizedWallets = [
    "0xD45C4F634A8f27dd24432711b310162fF6F846c2",
    "0x101F0e7E3e49f2C426763BCbb153eC9aCBc1cAA9",
    "0x7C856DACb2C1793392e3D93E0d1eADF17DdAaF1b",
    "0x672b4691821b0eC202395f66e86dcB3C83Ce0Dd0",
    "0xc2F3036C344535F7eAf7e7cA5950FC5CB2431Dc1",
    "0x77e98e5B139Ee11B12afBb74B1301fd61D31b38F",
    "bitgoddesses.eth",
    "0x7CD0b768240331DD6828FdE25faB054f6bE82177"
  ];
export default authorizedWallets;