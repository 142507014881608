import aboutCardIcon1 from "../../images/icon/numbers-2.png"
import aboutCardIcon2 from "../../images/icon/unique.png"
import aboutCardIcon3 from "../../images/icon/discord-4.png"

 const data = {
    aboutDescription1: 'Get ready to enter the digital jungle with our newly released limited edition Ordinal Social Kongs collection [inscriptions range 137k-157k]! These unique Bitcoin NFTs are the ultimate blend of art and technology, featuring stunning designs of Kong avatars inscribed on satoshis, making them the must-have item for any crypto enthusiast and art collector alike. ',
    aboutDescription2: 'Don\'t miss out on the chance to own a piece of history with these exclusive Ordinals - get yours now before they\'re all gone! Our genesis collection will include only 60 ordinals each with a unique golden style! OG Buyers will be entitled to receive a twin edition of the same ordinal in HiRes ETH NFT.',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Early inscriptions",
            text: "With inscriptions range from 137k up to 157k this exclusive collection is very attractive for collectors."
        },
        {
            icon: aboutCardIcon2,
            title: "Twin NFT Edition",
            text: 'The OG Ordinals buyers will be entitled to receive a twin edition of the same ordinal in HiRes ETH NFT.'
        },
        {
            icon: aboutCardIcon3,
            title: "Join our Discord",
            text: 'Join our community on Discord to find out how you can get your hands on these exclusive ordinals!'
        }
    ]
}

export default data;