import { ethers } from 'ethers';
import ContractABI from "./contractABI.json";



//indirizzo su Goerli
//const contractAddress = "0x44E989f570e14588d9A90729D2cb255f2Ef03279";
//indirizzo su Mainnet
const contractAddress = "0x5d7a4dFeCFcB1fc81D74628BD3b0A6020FbA11de";


//contract configuration
export const contractConfig = {
    address: contractAddress,
    abi: ContractABI,
}

//maximum mint per wallet
/* export const maxMintPerWalletCall = {
    ...contractConfig,
    functionName: 'limitPerWallet',
    watch: true,
} */
//maximum mint per wallet
export const maxMintPerWalletCall = {
    ...contractConfig,
    functionName: 'maxMintAmount', // Updated function name
    watch: true,
}

//maximum supply
export const maxSupplyCall = {
    ...contractConfig,
    functionName: 'maxSupply',
    watch: true,
}

//total minted items
export const totalMintedCall = {
    ...contractConfig,
    functionName: 'count',
    watch: true,
}

//free nfts
export const freeNFTsCall = {
    ...contractConfig,
    functionName: 'freeThreshold',
    watch: true,
}

//public mint cost
export const publicMintCostCall = {
    ...contractConfig,
    functionName: 'currentCost',
    watch: true,
}

// pause status
export const pauseStatusCall = {
    ...contractConfig,
    functionName: 'paused',
    watch: true,
}

//publicSaleStatus
export const publicSaleStatusCall = {
    ...contractConfig,
    functionName: 'publicSale',
    watch: true,
}

//public mint

export const publicMint = async (mint_amount) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);

    // Cambia la riga seguente
    let mintCost = await nftContract.currentCost(); // Era: let mintCost = await nftContract.cost.call();
    let mintCostPublic = parseFloat(mintCost) / 1000000000000000000;

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    let account = accounts[0];
    let txnHash;
    
    try {
        txnHash = await nftContract.mint(mint_amount, {
            gasLimit: "285000",
            value: ethers.utils.parseEther((mintCostPublic * mint_amount).toString())
        });
    } catch (error) {
        console.error("Transaction failed:", error);
        throw error;
    }

    return txnHash;
}