import thumb1 from "../images/ordinals/30.webp";
import thumb2 from "../images/ordinals/29.webp";
import thumb3 from "../images/ordinals/25.webp";
import thumb4 from "../images/ordinals/27.webp";
import thumb5 from "../images/ordinals/18.webp";
import thumb6 from "../images/ordinals/10.webp";
import thumb7 from "../images/ordinals/21.webp";
import thumb8 from "../images/ordinals/44.webp";
import thumb9 from "../images/ordinals/20.webp";
import thumb10 from "../images/ordinals/15.webp";
import thumb11 from "../images/ordinals/57.webp";
import thumb12 from "../images/ordinals/41.webp";
import thumb13 from "../images/ordinals/22.webp";
import thumb14 from "../images/ordinals/4.webp";
import thumb15 from "../images/ordinals/46.webp";
import thumb16 from "../images/ordinals/12.webp";
import thumb17 from "../images/ordinals/50.webp";
import thumb18 from "../images/ordinals/19.webp";
import thumb19 from "../images/ordinals/47.webp";
import thumb20 from "../images/ordinals/16.webp";
import thumb21 from "../images/ordinals/56.webp";
import thumb22 from "../images/ordinals/23.webp";
import thumb23 from "../images/ordinals/34.webp";
import thumb24 from "../images/ordinals/31.webp";
import thumb25 from "../images/ordinals/1.webp";
import thumb26 from "../images/ordinals/45.webp";
import thumb27 from "../images/ordinals/39.webp";
import thumb28 from "../images/ordinals/53.webp";
import thumb29 from "../images/ordinals/32.webp";
import thumb30 from "../images/ordinals/52.webp";
import thumb31 from "../images/ordinals/36.webp";
import thumb32 from "../images/ordinals/49.webp";
import thumb33 from "../images/ordinals/5.webp";
import thumb34 from "../images/ordinals/48.webp";
import thumb35 from "../images/ordinals/17.webp";
import thumb36 from "../images/ordinals/11.webp";
import thumb37 from "../images/ordinals/7.webp";
import thumb38 from "../images/ordinals/6.webp";
import thumb39 from "../images/ordinals/51.webp";
import thumb40 from "../images/ordinals/38.webp";
import thumb41 from "../images/ordinals/42.webp";
import thumb42 from "../images/ordinals/2.webp";
import thumb43 from "../images/ordinals/35.webp";
import thumb44 from "../images/ordinals/26.webp";
import thumb45 from "../images/ordinals/40.webp";
import thumb46 from "../images/ordinals/13.webp";
import thumb47 from "../images/ordinals/9.webp";
import thumb48 from "../images/ordinals/14.webp";
import thumb49 from "../images/ordinals/8.webp";
import thumb50 from "../images/ordinals/0.webp";
import thumb51 from "../images/ordinals/24.webp";
import thumb52 from "../images/ordinals/28.webp";
import thumb53 from "../images/ordinals/55.webp";
import thumb54 from "../images/ordinals/59.webp";
import thumb55 from "../images/ordinals/43.webp";
import thumb56 from "../images/ordinals/54.webp";
import thumb57 from "../images/ordinals/37.webp";
import thumb58 from "../images/ordinals/3.webp";
import thumb59 from "../images/ordinals/58.webp";
import thumb60 from "../images/ordinals/33.webp";


export const products = [
  {
    "thumb": thumb1,
    "title": "Inscription #145092",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "light",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "pacifier",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/ab0995a63c3993a5c6c3ca99eceaf6aa52376feecb6ec37ad2260a590f8d6c03i0"
  },
  {
    "thumb": thumb2,
    "title": "Inscription #145093",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "cigar",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/584b48ba94d656bc81a19eab4ccc688ff32ec14137dc70a4dac1449f09219f03i0"
  },
  {
    "thumb": thumb3,
    "title": "Inscription #144663",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden armour",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/85070a3c73b38cd314d322d12f07218508280b7813aef2a4b27fc8c33867200ai0"
  },
  {
    "thumb": thumb4,
    "title": "Inscription #144848",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/93c32671cddc421bbb5dcc5a449fea884d73bf36aa847d9d50423a11455ce915i0"
  },
  {
    "thumb": thumb5,
    "title": "Inscription #144456",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "golden hat",
      "extra",
      "kissgoodbye"
    ],
    "link": "https://ordinals.com/inscription/44e3e04f826302ed9d43257ff1824f71de5bd39d1802ced42baa456cccf7a216i0"
  },
  {
    "thumb": thumb6,
    "title": "Inscription #140679",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "sleepy",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown2",
      "extra",
      "kissgoodbye"
    ],
    "link": "https://ordinals.com/inscription/7d1c8caf03840ea395642238ed136562306b325933ee42ceacc41de138ff7c19i0"
  },
  {
    "thumb": thumb7,
    "title": "Inscription #144416",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "puzzled",
      "eyes",
      "dreamy",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "bitcoin"
    ],
    "link": "https://ordinals.com/inscription/0a382f26a138c9083b0b861eb7619a55eb8a16771b3052a244f03bb4846c272bi0"
  },
  {
    "thumb": thumb8,
    "title": "Inscription #150590",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "leopard",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "speechless",
      "eyes",
      "almost sleepy",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "golden hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/ed5d3bb5b97fe3b89bdf62f8d8a15210d81428b9cbd489e3406b94ffc111d139i0"
  },
  {
    "thumb": thumb9,
    "title": "Inscription #144478",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "light",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "speechless",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "gold phone"
    ],
    "link": "https://ordinals.com/inscription/c9712bc4e056b5ff4f83ebf0e78cf7b6450115c2bb753b164de1b757b124d739i0"
  },
  {
    "thumb": thumb10,
    "title": "Inscription #142067",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "party horn",
      "eyes",
      "orange eyes",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/bb69d47f83ca25245161e6ec7a7633735b1b6593f1b1b769df5fd9dee88b873ei0"
  },
  {
    "thumb": thumb11,
    "title": "Inscription #150959",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "dark",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "party horn",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/177685de86807b0ad8d1fc9f22616ac979c926950e53cb606a0f38c48bb7b93fi0"
  },
  {
    "thumb": thumb12,
    "title": "Inscription #150473",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "orange eyes",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "golder cap2",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/27b86027e6b8b5f01ff3220a59752844d2f0cdd7455396bc8a5b458c0d0f0140i0"
  },
  {
    "thumb": thumb13,
    "title": "Inscription #144494",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "tarzan",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "gold phone"
    ],
    "link": "https://ordinals.com/inscription/165c8a6e789613723008b28f500cc6e09ea90f9ba811b92365fbdd6ade776540i0"
  },
  {
    "thumb": thumb14,
    "title": "Inscription #139658",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "light",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "lightbulb",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "golder cap2",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/6fce30141ad7d16280deaebd6ff486d777978f5b79db382f24e5635d298fe046i0"
  },
  {
    "thumb": thumb15,
    "title": "Inscription #150761",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "speechless",
      "eyes",
      "sleepy",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown",
      "extra",
      "microphone"
    ],
    "link": "https://ordinals.com/inscription/b696db6df0108c007194b25cb7e50d705e6bc33dc6117dea7a47f2944b361948i0"
  },
  {
    "thumb": thumb16,
    "title": "Inscription #140819",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "leopard",
      "clothes",
      "tarzan",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/6b982126aded6a82afb771a99cc81f21c9cd60303f49d7cfafb5a5efd7e2f84fi0"
  },
  {
    "thumb": thumb17,
    "title": "Inscription #150826",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "light",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown",
      "extra",
      "bitcoin"
    ],
    "link": "https://ordinals.com/inscription/99bd55c73532a7c513dc99036d54e5570567b9a1cd879b2c004e344fbee16051i0"
  },
  {
    "thumb": thumb18,
    "title": "Inscription #144480",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "cigar",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/24092ae53f9a64acc6643f676b64c53d06583327fd46b6d399b74c7a3b52dc51i0"
  },
  {
    "thumb": thumb19,
    "title": "Inscription #150762",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "speechless",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown2",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/ebee547b284079b92176a5f1983d8ef0d4f2f4f8b891ab01badc936332d4a05ai0"
  },
  {
    "thumb": thumb20,
    "title": "Inscription #142079",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "light",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/478f7155ca636d291219cc0afd27bf724da40dd5b281e6a7c9a13d9b4bb82263i0"
  },
  {
    "thumb": thumb21,
    "title": "Inscription #150960",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "party horn",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/93fa97ce97c07023b89f66eae051aeaa0e5634b5ebe90af3c9884bbab8239b67i0"
  },
  {
    "thumb": thumb22,
    "title": "Inscription #144584",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden armour",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown2",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/787b6ab88cbaf598e3a17bb7e7f812ca55c73657c2c09b641e7840d217516c6bi0"
  },
  {
    "thumb": thumb23,
    "title": "Inscription #152674",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/bf0afdb8efaea43de33abdc21d7fa34f20176952ffb603c5065bd3473805d86bi0"
  },
  {
    "thumb": thumb24,
    "title": "Inscription #145660",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "leopard",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "pacifier",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/defa224f77bb0da677a4ef1bc7de983243e9201e48a7a3b921a534625895576di0"
  },
  {
    "thumb": thumb25,
    "title": "Inscription #139513",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "light",
      "clothes",
      "golden armour",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "GM"
    ],
    "link": "https://ordinals.com/inscription/262b1e35e912fac05ab63e11bc4bf292033d96febdd32bb693f30f3cce57cf6ei0"
  },
  {
    "thumb": thumb26,
    "title": "Inscription #150591",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden armour",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "thelodge",
      "extra",
      "gold phone"
    ],
    "link": "https://ordinals.com/inscription/890990c9fc37ecf0d67229c8bc6f499d6955cf46e32346b23e4f09418840b470i0"
  },
  {
    "thumb": thumb27,
    "title": "Inscription #152862",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "party horn",
      "eyes",
      "sleepy",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "golden hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/0283d725985666ca5a81f7e2e4c003f93b7369e7ce74c6edee97a2d29f4f7571i0"
  },
  {
    "thumb": thumb28,
    "title": "Inscription #150881",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "light",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "pacifier",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "golder cap2",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/1e208322a4239568ef5831a37d3b1b7c6faebbf091778dbe5aca7f244d066272i0"
  },
  {
    "thumb": thumb29,
    "title": "Inscription #145189",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "puzzled",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "bitcoin"
    ],
    "link": "https://ordinals.com/inscription/b1800326076805bf63ef5e47ebd125a6f78513222fd8ee29ed9d175c83f06375i0"
  },
  {
    "thumb": thumb30,
    "title": "Inscription #150882",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "standard ear",
      "glasses",
      "golden patch",
      "head",
      "thelodge",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/95d527e8463865b333f3e835c11c3ab0c4cd471882fbb581ece21914b1bb7475i0"
  },
  {
    "thumb": thumb31,
    "title": "Inscription #152874",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "party horn",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/697e3f64e811a70a9c2a4e26228eb89b55363c693a5aaaa7c1e71b99d4c0d878i0"
  },
  {
    "thumb": thumb32,
    "title": "Inscription #150792",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/312d23db6edb8bfabda658cc0cb8c4ad4caee173eab6ebd83a150e70564da67bi0"
  },
  {
    "thumb": thumb33,
    "title": "Inscription #156474",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold 2",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "very angy",
      "eyes",
      "angry",
      "ear",
      "standard ear",
      "glasses",
      "golden patch",
      "head",
      "golden pirate",
      "extra",
      "golden sword"
    ],
    "link": "https://ordinals.com/inscription/c237da28e4aa5b8ea01678b6df3a50194d67649843c02c1ca94527e49273e87ei0"
  },
  {
    "thumb": thumb34,
    "title": "Inscription #150793",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "orange eyes",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/7030eeb5e2c13d2c9ecd250fa9d25e49ef54a6064932f99d47195ad5c086777fi0"
  },
  {
    "thumb": thumb35,
    "title": "Inscription #144990",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "dark",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "cigar",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/e6684e5171b261c01fdd2f06e200bb936f0b0a04df9e55773d87915fd8c35d85i0"
  },
  {
    "thumb": thumb36,
    "title": "Inscription #140820",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "cigar",
      "eyes",
      "angrier",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/895da4fb28f7e2ce5598ee38ede91005d951e99a4720cafc21b3b5b2ff37d686i0"
  },
  {
    "thumb": thumb37,
    "title": "Inscription #139691",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "light",
      "clothes",
      "golden vest",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "sleepy",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "golden empirer"
    ],
    "link": "https://ordinals.com/inscription/9dbce0f376e8c0283d8a19c24ff69d1bb0050fb7503b75f89628d9eb7681fe8bi0"
  },
  {
    "thumb": thumb38,
    "title": "Inscription #139692",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "cigar",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "golder cap2",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/8865420be03f0e5fcfae2c22fdf420fb997e96aabb781f66aa0bc3a31fb9e48ei0"
  },
  {
    "thumb": thumb39,
    "title": "Inscription #150828",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "light",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "angry",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "bitcoin"
    ],
    "link": "https://ordinals.com/inscription/21ba59a4d2e397f552363637fddf8f22ec769b791fc01f380adfa0d264c77a90i0"
  },
  {
    "thumb": thumb40,
    "title": "Inscription #150883",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "piercing",
      "eyes",
      "almost sleepy",
      "ear",
      "standard ear",
      "glasses",
      "golden patch",
      "head",
      "golden pirate",
      "extra",
      "bitcoin shower"
    ],
    "link": "https://ordinals.com/inscription/a219ad577e96cba7965643109eb87e8a66547cc55a2cbabf1ec80f410ee87a97i0"
  },
  {
    "thumb": thumb41,
    "title": "Inscription #150694",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "puzzled",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/44b737b758cf3ddd2796fb1aa2d26474abe9057e2755cfe4b15052c18b6a739ci0"
  },
  {
    "thumb": thumb42,
    "title": "Inscription #139580",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "light",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "puzzled",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "microphone"
    ],
    "link": "https://ordinals.com/inscription/e1696a50013d6abbcc7ce6c398c1971ce6040365125837407ed2c006b5eaa29fi0"
  },
  {
    "thumb": thumb43,
    "title": "Inscription #153036",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "crown",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/5bc2a9ed80d2ea0c24cabdaa6e88140f1f671c09a888313d140ef1f9973dd4a0i0"
  },
  {
    "thumb": thumb44,
    "title": "Inscription #144820",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "leopard",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "cigar",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/fd805a8988a7c6bdc6e8e7945f04b9aa316d8f775592fcbe4ab09797386ec3a6i0"
  },
  {
    "thumb": thumb45,
    "title": "Inscription #150509",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "pacifier",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/efc3231475555f449c20541b477e4b2e29dacba3f9466505bbb0809a83cba5adi0"
  },
  {
    "thumb": thumb46,
    "title": "Inscription #142088",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "almost sleepy",
      "ear",
      "ear ring",
      "glasses",
      "golden patch",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/08ab395317cce42afdbe181e97ef5aab2146382d4609407d750d1bebbec135b0i0"
  },
  {
    "thumb": thumb47,
    "title": "Inscription #140686",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "yummy",
      "eyes",
      "angrier",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "burning money"
    ],
    "link": "https://ordinals.com/inscription/5885945f2a4f433318074066118fce1303f7cbd4f51f91829e99d34e99fb0eb3i0"
  },
  {
    "thumb": thumb48,
    "title": "Inscription #142091",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "light",
      "clothes",
      "golden armour",
      "nose",
      "nose ",
      "mouth",
      "speechless",
      "eyes",
      "orange eyes",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "thelodge",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/261cd79a027fd305e2d9467125d26f9217d0fe200d934b05da095bc4c5a384b5i0"
  },
  {
    "thumb": thumb49,
    "title": "Inscription #139807",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "orange eyes",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "gold phone"
    ],
    "link": "https://ordinals.com/inscription/a909e3808b792141e7a5ce43de83556ae4ff4773c9105c5bc6857909ad8798c2i0"
  },
  {
    "thumb": thumb50,
    "title": "Inscription #147498",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold",
      "body",
      "light",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/3134e46d4fac098d42c76412c4f5a8b34dc2aa662303212a62c8528e9d768ec5i0"
  },
  {
    "thumb": thumb51,
    "title": "Inscription #144602",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "speechless",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "burning money"
    ],
    "link": "https://ordinals.com/inscription/26f1050685080d784c5d3878e0ea18a9eff1301b89811b673c498771401d48c6i0"
  },
  {
    "thumb": thumb52,
    "title": "Inscription #147503",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "light",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "hungry",
      "eyes",
      "gazing",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "gold phone"
    ],
    "link": "https://ordinals.com/inscription/141063ddcd10e2de89953d676325e98cdf3229dfe376e7dd8d07e555738ea6c8i0"
  },
  {
    "thumb": thumb53,
    "title": "Inscription #150961",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "golden pirate",
      "extra",
      "gold phone"
    ],
    "link": "https://ordinals.com/inscription/8dab5a3d82374e6aa3d2a004825d7f3a323b6f16696e8908433d611aa8a8aacei0"
  },
  {
    "thumb": thumb54,
    "title": "Inscription #150962",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "purple",
      "body",
      "light",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/fef4177d6316dc365ccc380454cf0cb56ccd79f35031e1ae3921e35ac17738d0i0"
  },
  {
    "thumb": thumb55,
    "title": "Inscription #150439",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "puzzled",
      "eyes",
      "angrier",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "burning money"
    ],
    "link": "https://ordinals.com/inscription/b7dc3aade0f2f7fb1af04a2f2d65f7ed5c089b43f1d63cbdc12485d4828304dbi0"
  },
  {
    "thumb": thumb56,
    "title": "Inscription #156475",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold 2",
      "body",
      "leopard",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "cigar",
      "eyes",
      "angrier",
      "ear",
      "standard ear",
      "glasses",
      "Invisible glasses",
      "head",
      "golden hat",
      "extra",
      "burning money and bitcoin shower"
    ],
    "link": "https://ordinals.com/inscription/553f246bd46f1adf238b025b04a9c76b3e65feb30a8d3c3ac6fcb566ed261de0i0"
  },
  {
    "thumb": thumb57,
    "title": "Inscription #153572",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new orange",
      "body",
      "dark",
      "clothes",
      "golden vest2",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "dreamy",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "Invisible hat",
      "extra",
      "microphone"
    ],
    "link": "https://ordinals.com/inscription/db4846cf5d4dbf945b5bc7e8dd2f4b9c3b17371f3edef053d06c0324247254e2i0"
  },
  {
    "thumb": thumb58,
    "title": "Inscription #139581",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "standard mouth",
      "eyes",
      "suspicious",
      "ear",
      "ear ring",
      "glasses",
      "golden glasses",
      "head",
      "golder cap2",
      "extra",
      "bitcoin shower"
    ],
    "link": "https://ordinals.com/inscription/8c00b157470a87fc4e60bcd6d5112be8ac6e92676c4927f8c39b967b8b7231e5i0"
  },
  {
    "thumb": thumb59,
    "title": "Inscription #156425",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "gold 2",
      "body",
      "leopard",
      "clothes",
      "golden armor",
      "nose",
      "nose ",
      "mouth",
      "yummy",
      "eyes",
      "gazing",
      "ear",
      "standard ear",
      "glasses",
      "Invisible glasses",
      "head",
      "golden pirate",
      "extra",
      "golden sword"
    ],
    "link": "https://ordinals.com/inscription/e2fd86974fc37685862c36958ff505a2810e1994de4395d37df6f8c85715f4f2i0"
  },
  {
    "thumb": thumb60,
    "title": "Inscription #157173",
    "price": "0.25 ETH",
    "likes": "124",
    "categories": [
      "background",
      "new emerald",
      "body",
      "dark",
      "clothes",
      "golden suit",
      "nose",
      "nose ",
      "mouth",
      "puzzled",
      "eyes",
      "thinking",
      "ear",
      "ear ring",
      "glasses",
      "Invisible glasses",
      "head",
      "Invisible hat",
      "extra",
      "invisible hammer"
    ],
    "link": "https://ordinals.com/inscription/047b787f92113eb126d413ab83a9bb69050a03981089348cb145cecdbf1ceffai0"
  }
];

export const categories = [
    {
      widgetTitle: "BACKGROUND",
      items: [
        {
          title: "gold",
          products: "23",
        },
        {
          title: "gold 2",
          products: "18",
        },
        {
          title: "new emerald",
          products: "13",
        },
        {
          title: "new orange",
          products: "24",
        },
        {
          title: "purple",
          products: "8",
        },
      ],
    },
    {
      widgetTitle: "Body",
      items: [
        {
          title: "dark",
          products: "67",
        },
        {
          title: "leopard",
          products: "35",
        },
        {
          title: "light",
          products: "43",
        },
      ],
    },
    {
      widgetTitle: "Clothes",
      items: [
        {
          title: "golden armor",
          products: "105",
        },
        {
          title: "golden armour",
          products: "78",
        },
        {
          title: "golden suit",
          products: "33",
        },
        {
          title: "golden vest",
          products: "41",
        },
        {
          title: "golden vest 2",
          products: "41",
        },
        {
          title: "tarzan",
          products: "41",
        },
      ],
    },
    {
      widgetTitle: "Ear",
      items: [
        {
          title: "standard ear",
          products: "105",
        },
        {
          title: "ear ring",
          products: "78",
        },
      ],
    },
    {
      widgetTitle: "extra",
      items: [
        {
          title: "Good Morning",
          products: "105",
        },
        {
          title: "bitcoin",
          products: "78",
        },
        {
          title: "bitcoin shower",
          products: "78",
        },
        {
          title: "burning money",
          products: "78",
        },
        {
          title: "burning money & bitcoin shower",
          products: "78",
        },
        {
          title: "gold phone",
          products: "78",
        },
        {
          title: "golden empire state building",
          products: "78",
        },
        {
          title: "golden sword",
          products: "78",
        },
        {
          title: "invisible hammer",
          products: "78",
        },
        {
          title: "kissgoodbye",
          products: "78",
        },
        {
          title: "microphone",
          products: "78",
        },
      ],
    },
    {
      widgetTitle: "eyes",
      items: [
        {
          title: "almost sleepy",
          products: "105",
        },
        {
          title: "angrier",
          products: "78",
        },
        {
          title: "angry",
          products: "78",
        },
        {
          title: "dreamy",
          products: "78",
        },
        {
          title: "gazing",
          products: "78",
        },
        {
          title: "orange eyes",
          products: "78",
        },
        {
          title: "sleepy",
          products: "78",
        },
        {
          title: "suspicious",
          products: "78",
        },
        {
          title: "thinking",
          products: "78",
        },
      ],
    },
    {
      widgetTitle: "glasses",
      items: [
        {
          title: "invisible glasses",
          products: "105",
        },
        {
          title: "golden glasses",
          products: "78",
        },
        {
          title: "golden patch",
          products: "78",
        },
      ],
    },
    {
      widgetTitle: "head",
      items: [
        {
          title: "invisible hat",
          products: "105",
        },
        {
          title: "crown",
          products: "78",
        },
        {
          title: "crown 2",
          products: "78",
        },
        {
          title: "golden hat",
          products: "78",
        },
        {
          title: "golden pirate",
          products: "78",
        },
        {
          title: "golden cap2",
          products: "78",
        },
        {
          title: "the lodge",
          products: "78",
        },
      ],
    },
    {
      widgetTitle: "mouth",
      items: [
        {
          title: "cigar",
          products: "105",
        },
        {
          title: "hungry",
          products: "78",
        },
        {
          title: "lightbulb",
          products: "78",
        },
        {
          title: "pacifier",
          products: "78",
        },
        {
          title: "party horn",
          products: "78",
        },
        {
          title: "piercing",
          products: "78",
        },
        {
          title: "puzzled",
          products: "78",
        },
        {
          title: "speechless",
          products: "78",
        },
        {
          title: "very angry",
          products: "78",
        },
        {
          title: "yummy",
          products: "78",
        },
      ],
    },
  ];