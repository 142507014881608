import React, { useCallback, useRef,useState } from "react";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import mintImg from "../../../assets/images/icon/socialkongs.gif";
import socialContractImg from "../../../assets/images/icon/socialcontract.gif";
import errorImg from "../../../assets/images/icon/error.gif";
import successImg from "../../../assets/images/icon/success.png";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import ReactCanvasConfetti from "react-canvas-confetti";

import { useAccount, useContractRead } from 'wagmi';
import { useEffect } from "react";
import {
  maxSupplyCall,
  totalMintedCall,
  maxMintPerWalletCall,
  publicMintCostCall,
  publicMint,
  freeNFTsCall,
} from "../../../contract/config";


const MintNowModal = () => {
  let [count, setCount] = useState(1);
  let [price, setPrice] = useState("0.001");
  const { mintModalHandle } = useModal();
  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  const [currentImage, setCurrentImage] = useState(mintImg);
  const [buttonStatus, setButtonStatus] = useState('idle');
  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  };
  function getAnimationSettings(originXA, originXB) {
    return {
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: 0,
      particleCount: 150,
      origin: {
        x: randomInRange(originXA, originXB),
        y: Math.random() - 0.2
      }
    };
  }
  
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 400));
    }
  }, [intervalId, nextTickAnimation]);

  const pauseAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const [totalSupply, setTotalSupply] = useState(10000);
  const [totalMinted, setTotalMinted] = useState(0);
  const { data: totalmintedData } = useContractRead({ ...totalMintedCall })
  const { data: freeNTFsData} = useContractRead({ ...freeNFTsCall })
  const [remainingItem, setRemainingItem] = useState(totalSupply - totalMinted);
  const [maxMintPerWallet, setMaxMintPerWallet] = useState(1);
  const [publicMintCost, setPublicMintCost] = useState(0);
  const [freeNFTs, setFreeNFTs] = useState(3000);

  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  const { data: maxSupplyData } = useContractRead({ ...maxSupplyCall })
  const { data: totalMintedData } = useContractRead({ ...totalMintedCall })
  const { data: maxMintPerWalletData } = useContractRead({ ...maxMintPerWalletCall })
  const { data: publicMintCostData } = useContractRead({ ...publicMintCostCall })
  const { data: freeNFTsData } = useContractRead({ ...freeNFTsCall })

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  useEffect(() => {
    if (isConnected) {
      if (maxSupplyData) {
        setTotalSupply(maxSupplyData.toString());
      }
      if (totalMintedData) {
        setTotalMinted(totalMintedData.toString());
      }
      /* if(maxSupplyData && totalMintedData){
        setRemainingItem(freeNFTs - totalMinted);
      } */
      if (maxMintPerWalletData) {
        setMaxMintPerWallet(maxMintPerWalletData.toString());
      }
      if (publicMintCostData) {
        setPublicMintCost(publicMintCostData.toString() / 1000000000000000000);
      }
      if (freeNFTsData) {
        setFreeNFTs(freeNFTsData.toString());
      }
    }
  },[isConnected, maxSupplyData, totalMintedData, maxMintPerWalletData, publicMintCostData, freeNFTsData]);
  useEffect(() => {
    setPrice(publicMintCost); // Aggiorna il prezzo quando il valore di publicMintCost cambia
  }, [publicMintCost]);
  useEffect(() => {
    const totalMintedNum = parseInt(totalMinted);
    const freeNFTsNum = parseInt(freeNFTs);
  
    if (totalMintedNum <= freeNFTsNum) {
      setRemainingItem(freeNFTsNum - totalMintedNum);
      setButtonsDisabled(true);
    } else {
      setRemainingItem(totalSupply - totalMintedNum);
      setButtonsDisabled(false);
    }
  }, [totalMinted, totalSupply, freeNFTs]);
  useEffect(() => {
    setButtonStatus('idle');
  }, [mintModalHandle]);
  
  
  const decreaseCount = () => {
    count -= 1;
    price = publicMintCost * count;
    if (count < 1) {
      count = 1;
      setCount(1);
      setPrice(publicMintCost);
    }
    else {
      setCount(count);
      setPrice(price);
    }
  }

  const increaseCount = () => {
    count += 1;
    price = publicMintCost * count;
    if (count > maxMintPerWallet) {
      count = maxMintPerWallet;
      setPrice(publicMintCost * maxMintPerWallet);
    }
    else {
      setCount(count);
      setPrice(price);
    }
  }
  

  const mintNow = async () => {
    if (isConnected) {
      try {
        setButtonStatus('waiting');
        setCurrentImage(socialContractImg);
        const txn = await publicMint(count);
        console.log("Transaction sent:", txn);
  
        // Wait for the transaction to be confirmed or fail
        const receipt = await txn.wait();
        console.log("Transaction confirmed:", receipt);
  
        // Close the modal if the transaction is successful
        if (receipt.status === 1) {
          setCurrentImage(successImg);
          setButtonStatus('success');
          startAnimation();
          //mintModalHandle();
        }
      } catch (error) {
        setCurrentImage(errorImg);
        setButtonStatus('error');
        console.error("Transaction error:", error);
      }
    }
  };
  

  return (
    <>
      <MintModalStyleWrapper className="modal_overlay">
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Collect YOUR {totalMintedData <= freeNFTsData ? "FREE" : ""} NFT before end</h2>
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>

            <div className="modal_body text-center">
              <div className="mint_img">
                <img src={currentImage} alt="bithu nft mint" />
              </div>
              <div className="mint_count_list">
                <ul>
                  <li>
                    <h5>Minted <em>{totalMintedData <= freeNFTsData ? "Free" :""}</em></h5>
                    <h5>
                      {totalMinted }/<span>{totalMintedData <= freeNFTsData ? freeNFTsData.toString() : maxSupplyData.toString()}</span>
                    </h5>
                  </li>
                  <li>
                    <h5>Price</h5>
                    <h5>{publicMintCost} ETH</h5>
                  </li>
                  <li>
                    <h5>Quantity</h5>
                    <div className="mint_quantity_sect">
                      <button onClick={() => decreaseCount()} disabled={buttonsDisabled}>-</button>
                      <input
                        type="text"
                        id="quantity"
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                      />
                      <button onClick={() => increaseCount()} disabled={buttonsDisabled}>+</button>
                    </div>
                    <h5>
                      <span>{parseFloat(price).toFixed(3)}</span> ETH
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="modal_mint_btn">
                <Button lg variant={buttonStatus === 'idle' ? 'mint' : 'outline'} onClick={() => mintNow()} disabled={buttonStatus !== 'idle'}>
                  {buttonStatus === 'idle'
                  ? 'Mint Now'
                  : buttonStatus === 'waiting'
                  ? 'Waiting'
                  : buttonStatus === 'success'
                  ? 'Success'
                  : 'Error'}
                </Button>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export default MintNowModal;
