import { FaLinkedinIn, FaTwitter, FaInstagram, FaGlobe } from "react-icons/fa";

import avatarThumb1 from "../../images/team/danny.png";
import avatarThumb2 from "../../images/team/charlie.jpg";
import avatarThumb3 from "../../images/team/massimo.png";
import avatarThumb4 from "../../images/team/bitgodesses.jpg";
import avatarThumb5 from "../../images/team/shadow.png";
import avatarThumb6 from "../../images/team/shadow.png";
import avatarThumb7 from "../../images/team/shadow.png";
import avatarThumb8 from "../../images/team/nuvolaproject.png";

const data = [
  {
    avatar: avatarThumb1,
    name: "Danny Crane",
    designation: "Co-Founder",
    socialLinks: [
    ],
  },
  {
    avatar: avatarThumb2,
    name: "Charlie Gian",
    designation: "Co-Founder",
    socialLinks: [
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/gattok",
      },
      
    ],
  },
  {
    avatar: avatarThumb3,
    name: "Max Di Leo",
    designation: "Co-Founder & Artist",
    socialLinks: [
      {
        icon: <FaGlobe />,
        url: "https://massimo.im/",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/massimodileo",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/massimodileo/",
      },
    ],
  },
  {
    avatar: avatarThumb4,
    name: "bitgodesses",
    designation: "Socials and Community",
    socialLinks: [
      {
        icon: <FaTwitter />,
        url: "http://twitter.com/bitgoddesses",
      },
      {
        icon: <FaInstagram />,
        url: "http://instagram.com/bitgoddesses",
      },
    ],
  },
  
];

export default data;
