import characterThumb1 from "../../images/nft/carousel_kong_1.png";
import characterThumb2 from "../../images/nft/carousel_kong_2.png";
import characterThumb3 from "../../images/nft/carousel_kong_3.png";
import characterThumb4 from "../../images/nft/carousel_kong_4.png";
import characterThumb5 from "../../images/nft/carousel_kong_5.png";
import characterThumb6 from "../../images/nft/carousel_kong_6.png";
import characterThumb7 from "../../images/nft/carousel_kong_7.png";
import characterThumb8 from "../../images/nft/carousel_kong_8.png";
import characterThumb9 from "../../images/nft/carousel_kong_9.png";
import characterThumb10 from "../../images/nft/carousel_kong_10.png";
import characterThumb11 from "../../images/nft/carousel_kong_11.png";

const data = [
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb2,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb4,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb7,
  },
  {
    thumb: characterThumb8,
  },
  {
    thumb: characterThumb9,
  },
  {
    thumb: characterThumb10,
  },
  {
    thumb: characterThumb11,
  },
];

export default data;
