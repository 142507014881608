import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/ab-img2.png";
import aboutIcon from "../../images/icon/banana.png";

const data = {
  aboutDescription1:
    'Introducing the Social Kongs - a new 10K pieces NFT collection where gorillas step up to the plate and show us how it\'s done! These social savvy primates have learned to mimic our every move and have become the masters of the social scene. From donning the finest attire to posting their latest adventures on social media, these Kongs are taking the world by storm.',
  aboutDescription2:
    'Get ready to meet the most hilarious and playful bunch of apes you\'ll ever encounter, all while taking a hilarious, yet thought-provoking look at our own social lives! Get in on the fun and make some new friends with the Social Kongs.',
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Orange",
      role: "Variant [leopard] - rarity: 6.75",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Light",
      role: "Variant [leopard] - rarity: 10.45",
    },
  ],
};

export default data;
