import checkIcon from "../../images/blog/arrow.png";
import spinnerIcon from "../../images/icon/roadmap_spiner.png"
import thumb1 from "../../images/blog/roadmap-img.png";
import thumb2 from "../../images/blog/roadmap-img2.png"; 
import thumb3 from "../../images/blog/roadmap-img3.png";
import thumb4 from "../../images/blog/roadmap-img4.png";
import thumb5 from "../../images/blog/roadmap-img5.png";
import thumb6 from "../../images/blog/roadmap-img5.png";
const data = [
  {
    progressIcon: checkIcon,
    progress: "10",
    title: "Free Giveaway",
    text: "Get ready to unleash the power of the Social Kongs! In this first phase, we're giving away 3,000 of our photorealistic NFTs for FREE. That's right, you heard it right! You can grab one (or more) of these limited edition NFTs before they're gone, but there's a catch - only one per wallet. So, act fast and claim your Social Kong now!",
    thumb: thumb1,
  },
  {
    progressIcon: spinnerIcon,
    progress: "20",
    title: "Retail Release",
    text: "The Social Kongs are making their grand entry into the NFT world, and they're bringing their A-game! The next 7,000 NFTs will be available for purchase at a retail price of 0.02 ETH, with a maximum of 20 NFTs per wallet. This is your chance to complete your collection and show off your Social Kong pride!",
    thumb: thumb2,
  },
  {
    progressIcon: spinnerIcon,
    progress: "50",
    title: "Merchandise",
    text: "The Social Kongs are not just digital art, they're a lifestyle! In this phase, we're bringing the fun to your physical world with a range of exclusive merchandise. From t-shirts to stickers, you'll be able to show off your Social Kongs love in style!",
    thumb: thumb3,
  },
  {
    progressIcon: spinnerIcon,
    progress: "75",
    title: "Exclusive & Limited Editions",
    text: "Get ready to discover the world of Social Kongs like never before with our limited and exclusive themed editions! Inspired by iconic movies and TV shows from the 80s and 90s, these editions will be a true collector's dream come true. Each piece will be a unique work of art, bringing the characters you love to life in a whole new way. Stay tuned for more information on these exciting releases!",
    thumb: thumb4,
  },
  {
    progressIcon: spinnerIcon,
    progress: "100",
    title: "Emotional Edition",
    text: "The Social Kongs are more than just cute, they're emotional! In this phase, you'll be able to interact with your NFTs like never before. By sending tweets, you'll be able to change your Social Kongs' moods and show the world how much you love them!",
    thumb: thumb5,
  },
  {
    progressIcon: spinnerIcon,
    progress: "100",
    title: "Animated Series",
    text: "The Social Kongs are taking over the world! If the collection is a success in every phase, we'll be producing an animated series based on these lovable characters. So, stay tuned and get ready to join the Social Kongs on their wild adventures!",
    thumb: thumb6,
  },
];

export default data;
