import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import { FaDiscord, FaWallet } from "react-icons/fa";
import { ConnectButton } from '@rainbow-me/rainbowkit';

import characterThumb from "../../../../assets/images/nft/home-kong-transparent.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";

import authorizedWallets from "../../../../assets/data/wallets/authorizedWallets";
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { useState, useEffect } from "react";
import {
  maxSupplyCall,
  totalMintedCall,
  maxMintPerWalletCall,
  publicMintCostCall,
  pauseStatusCall, 
  publicSaleStatusCall,
} from "../../../../contract/config";

const Banner = () => {
  const { mintModalHandle } = useModal();
  const { walletModalHandle } = useModal();
  const [totalSupply, setTotalSupply] = useState(10000);
  const [totalMinted, setTotalMinted] = useState(1);
  const [remainingItem, setRemainingItem] = useState(9999);
  const [maxMintPerWallet, setMaxMintPerWallet] = useState(20);
  const [publicMintCost, setPublicMintCost] = useState(0.02);

  
  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  /* const { data: maxSupplyData } = useContractRead({ ...maxSupplyCall })
  const { data: totalMintedData } = useContractRead({ ...totalMintedCall })
  const { data: maxMintPerWalletData } = useContractRead({ ...maxMintPerWalletCall })
  const { data: publicMintCostData } = useContractRead({ ...publicMintCostCall })
  const { data: isPausedData } = useContractRead({ ...pauseStatusCall })
   const { data: publicSaleStatusData } = useContractRead({ ...publicSaleStatusCall }) // Legge lo stato della public sale
 */
  const { data: maxSupplyData } = useContractRead(isConnected ? { ...maxSupplyCall } : 10000);
  const { data: totalMintedData } = useContractRead(isConnected ? { ...totalMintedCall } : 1);
  const { data: maxMintPerWalletData } = useContractRead(isConnected ? { ...maxMintPerWalletCall } : 20);
  const { data: publicMintCostData } = useContractRead(isConnected ? { ...publicMintCostCall } : 0.02);
  const { data: isPausedData } = useContractRead(isConnected ? { ...pauseStatusCall } : false);
  const { data: publicSaleStatusData } = useContractRead(isConnected ? { ...publicSaleStatusCall } : false); 

  
  //const [isFollowing, setIsFollowing] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
 

  useEffect(() => {
    if (isConnected) {
      if (maxSupplyData) {
        setTotalSupply(maxSupplyData.toString());
      }
      if (totalMintedData) {
        setTotalMinted(totalMintedData.toString());
      }
      if (maxSupplyData && totalMintedData) {
        setRemainingItem(totalSupply - totalMinted);
      }
      if (maxMintPerWalletData) {
        setMaxMintPerWallet(maxMintPerWalletData.toString());
      }
      if (publicMintCostData) {
        setPublicMintCost(publicMintCostData.toString() / 1000000000000000000);
      }
      setIsAuthorized(authorizedWallets.includes(address)); 
    }
  }, [isConnected, maxSupplyData, totalMintedData, totalSupply, maxMintPerWalletData, publicMintCostData, address]);
  
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2>Social Kongs 🍌 NFT collection</h2>
              {
                isConnected && (
               <h3>
                <span className="count">
                  <Counter end={totalMinted} duration={totalMinted} />
                </span>{" "}
                / {totalSupply} Minted
              </h3> 
                )}
               <div className="banner_buttons">
                  {!isPausedData ? (
                    publicSaleStatusData ||
                    (publicSaleStatusData === false && isAuthorized) ? (
                      <Button lg variant="mint" onClick={() => mintModalHandle()}>
                        {" "}
                        Mint now
                      </Button>
                    ) : ( isConnected ? (
                      <Button lg variant="blue">
                        {" "}
                        PUBLIC MINT OPENS SOON
                      </Button>) : (<Button lg variant="connect">
                        {" "}
                        <FaWallet />
                        <ConnectButton
                        label="Connect"
                        chainStatus="none"//icon,name,none
                        showBalance={false}//true,false
                        accountStatus="address"//avatar,address,
                        />
                      </Button>
                    ))
                  ) : ( isConnected ? (
                    <Button lg variant="mint">
                      {" "}
                      MINT WILL OPEN SOON
                    </Button>) : (
                      <Button lg variant="connect">
                        {" "}
                        <FaWallet />
                        <ConnectButton
                        label="Connect"
                        chainStatus="none"//icon,name,none
                        showBalance={false}//true,false
                        accountStatus="address"//avatar,address,
                        />
                      </Button>
                  ))}
                  <a href="https://discord.gg/vpRKtCneQG" target="_blank">
                    <Button lg variant="blue" className="join_discord_btn">
                      {" "}
                      <FaDiscord /> Join Discord
                    </Button>
                  </a> 
                  {/* <a href="https://form.jotform.com/230732334260042" target="_blank">
                    <Button lg variant="outline">
                      Join Waitlist Now
                    </Button>
                  </a>*/}
              </div>
              <div className="coin-info">
                {
                 ( isConnected ? (
                  <span>
                    FREE MINT: {" "}
                    <span className="highlighted">{3000 - totalMinted}</span>
                    {" "} FREE STILL AVAILABLE!
                  </span>
                ) : (
                <span>
                  CONNECT <FaWallet /> TO CHEK FREE MINTS STILL AVAILABLE
                </span>
                )
                 )
              }
                <span>FREE MINT FOR THE FIRST 3000 SK (MAX 1 PER WALLET) </span>
                <span>THEN  <span className="highlighted">0.02</span> ETH + gas (Max {maxMintPerWallet} SK per wallet)</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div>
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avatar" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
