import checkIcon from "../../images/icon/banana.png"
import spinnerIcon from "../../images/icon/banana-2.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase 00 ",
        subtitle: "ORDINALS SOCIAL KONGS LIMITED EDITION",
        text: "An exclusive collection of only 60 Kongs each with a unique golden style released in the new ordinal format. Join the new bitcoin NFTs revolution with Ordinal Social Kongs! Ordinals are a type of non-fungible token (NFT) that are inscribed on the Bitcoin blockchain, making them among the first Bitcoin NFTs in the world. Each ordinal is a unique piece of art, featuring a stunning design of a Kong avatar inscribed on a satoshi."
    }, 
    {
        icon: checkIcon,
        title: "Phase 01 ",
        subtitle: "Free Release",
        text: "Get ready to unleash the power of the Social Kongs! In this first phase, we're giving away 3,000 of our photorealistic NFTs for FREE. That's right, you heard it right! You can grab one (or more) of these limited edition NFTs before they're gone, but there's a catch - only one per wallet. So, act fast and claim your Social Kong now!"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 02",
        subtitle: "Retail Release",
        text: "The Social Kongs are making their grand entry into the NFT world, and they're bringing their A-game! The next 7,000 NFTs will be available for purchase at a retail price of 0.02 ETH, with a maximum of 20 NFTs per wallet. This is your chance to complete your collection and show off your Social Kong pride!"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 03",
        subtitle: "Merchandise",
        text: "The Social Kongs are not just digital art, they're a lifestyle! In this phase, we're bringing the fun to your physical world with a range of exclusive merchandise. From t-shirts to stickers, you'll be able to show off your Social Kongs in style!"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 04",
        subtitle: "Exclusive & Limited",
        text: "Get ready to discover the world of Social Kongs like never before with our limited and exclusive themed editions! Inspired by iconic movies and TV shows from the 80s and 90s, these editions will be a true collector's dream come true. Each piece will be a unique work of art, bringing the characters you love to life in a whole new way. Stay tuned for more information on these exciting releases!"
    },
    {
        icon: spinnerIcon,
        title: "Phase 05",
        subtitle: "Emotional Edition",
        text: "The Social Kongs are more than just cute, they're emotional! In this phase, you'll be able to interact with your NFTs like never before. By sending tweets, you'll be able to change your Social Kongs' moods and show the world how much you love them!"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 06",
        subtitle: "Animated Series",
        text: "The Social Kongs are taking over the world! If the collection is a success in every phase, we'll be producing an animated series based on these lovable characters. So, stay tuned and get ready to join the Social Kongs on their wild adventures!"
    },  
]

export default data;