import { FaRegHeart } from "react-icons/fa";
import ProductStyleWrapper from "./Product.style";

const Product = ({ thumb, title, link }) => {
  return (
    <ProductStyleWrapper>
      <div className="product_thumb">
        <img src={thumb} alt="thumb" />
      </div>
      <div className="product_details">
        <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
      </div>
    </ProductStyleWrapper>
  );
};

export default Product;
