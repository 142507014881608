import styled from "styled-components";

const TeamStyleWrapper = styled.section`
  background: rgba(25, 35, 40, 0.5);
  backdrop-filter: blur(5px);
  position: relative;
  z-index: 1;
  h2 {
    color: #ffd791;
  }
  .v1_team_overlay {
    padding: 113px 0 140px 0;
    position: relative;
    width: 100%;
    height: 100%;
    background: #040c12;
    z-index: 1;
  }

  .bithu_team_content {
    margin-top: 53px;
    position: relative;
    z-index: 1;
    .row {
      row-gap: 30px;
    }
    .bithu_team_card {
      border-radius: 30px 0px 30px 0px;
    }
    .team_social_icon {
      bottom:10px;
    }
  }
  .team_member_img {
    max-width:120px;
    overflow: hidden;
    border-radius: 20px 0px 20px 0px;
  }

  @media only screen and (max-width: 667px) {
    .v1_team_overlay {
      padding: 30px 0 100px 0;
    }
    .bithu_team_content {
      .bithu_team_card {
        border-radius: 0px;
      }
      .team_member_img {
        max-width:120px;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }
`;

export default TeamStyleWrapper;
