import { useState } from "react";
import FilterBox from "./filterBox/FilterBox";
import { FaBitcoin } from "react-icons/fa";
import Product from "./product/Product";
import Pagination from "../../../common/pagination/Pagination";
import Button from "../../../common/button";
import CollectionStyleWrapper from "./Collection.style";

import { products, categories } from "../../../assets/data/ordinals";

const Collection = ({ itemsPerPage }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;

  const handleCategoryChange = (category, isSelected) => {
    if (isSelected) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    }
    setCurrentPage(1); // reset current page when category changes
  };

  // Aggiungi questa funzione nel componente Collection
const getFilteredProductCountForCategory = (category) => {
  return products.filter(
    (product) => product.categories && product.categories.includes(category)
  ).length;
};

  // Modifica la parte che renderizza il componente FilterBox
  {categories?.map((category, idx) => (
    <FilterBox
      key={idx}
      {...category}
      onCategoryChange={handleCategoryChange}
      isSelected={(item) => selectedCategories.includes(item)}
      filteredProductCount={getFilteredProductCountForCategory(category.title)} // Aggiungi questa riga
    />
  ))}
  
  const getFilteredProducts = () => {
    if (selectedCategories.length === 0) {
      return products;
    }
  
    // Raggruppa i filtri selezionati per categoria
    const selectedCategoriesByGroup = {};
    selectedCategories.forEach((selectedCategory) => {
      const group = categories.find((category) =>
        category.items.some((item) => item.title === selectedCategory)
      );
  
      if (group) {
        if (!selectedCategoriesByGroup[group.widgetTitle]) {
          selectedCategoriesByGroup[group.widgetTitle] = [];
        }
        selectedCategoriesByGroup[group.widgetTitle].push(selectedCategory);
      }
      });
    
      // Filtra i prodotti utilizzando l'operatore OR all'interno di una categoria e l'operatore AND tra categorie diverse
      return products.filter((product) => {
        return Object.values(selectedCategoriesByGroup).every((groupSelectedCategories) =>
          groupSelectedCategories.some((category) =>
            product.categories && product.categories.includes(category)
          )
        );
      });
    };
  
  
  

  const filteredProducts = getFilteredProducts();
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const paginatedData = currentProducts;
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  return (
    <CollectionStyleWrapper>
      <div className="container">
        <div className="row filters_row">
          <div className="col-lg-3 col-md-4">
            <span><a href="https://gamma.io/ordinals/collections/ordinal-social-kongs" target="_blank" >
                      <Button lg variant="blue">
                      <FaBitcoin />
                        Buy one
                      </Button>
            </a><br/></span>
            {categories?.map((category, idx) => (
              <FilterBox
                key={idx}
                {...category}
                onCategoryChange={handleCategoryChange}
                isSelected={(item) => selectedCategories.includes(item)}
                getFilteredProducts={getFilteredProducts}
                products={products}
              />
            ))}
          </div>
          <div className="col-lg-9 col-md-8">
            <div className="collection_items">
              <div className="row products_row">
                {currentProducts?.map((product, idx) => (
                  <div key={idx} className="col-lg-3 col-sm-6 col-12">
                    <Product {...product} />
                  </div>
                ))}
              </div>
            </div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
            />
          </div>
        </div>
      </div>
    </CollectionStyleWrapper>
  );
};

export default Collection;