const data = [
  {
    category: "The Social Kongs",
    items: [
      {
        title: "What is this collection ?",
        text: "The Social Kongs NFT Collection is a unique, limited-edition series of digital art pieces that represent our social quirks and habits. Each piece is designed with almost photorealistic graphics and captures the essence of how we present ourselves on social media.",
      },
      {
        title: "What is the theme of the Social Kongs NFT Collection ?",
        text: "The theme of the Social Kongs NFT Collection is a fun parody of the social monkeys we have become today. Each piece represents our social quirks and habits and captures the essence of how we present ourselves on social media.",
      },
      {
        title: "What makes the graphics of the Social Kongs NFT Collection so special ?",
        text: "The Social Kongs NFT Collection boasts breathtaking graphics that set it apart from the rest. Expert graphic artists used Photoshop to create highly detailed and photorealistic images, elevating this collection above the average AI-generated NFTs or lackluster sketches and 3D renderings. Experience art that is both personal and breathtaking with Social Kongs NFT Collection.",
      },
      {
        title: "What kind of themes can we expect from future limited and exclusive collections ?",
        text: "The next collections of Social Kongs will showcase their passion for movies and TV shows. Already, attentive viewers can spot references to popular film and TV characters from the 80s and 90s, among others. In future collections, these elements will become even more prominent and give rise to limited and exclusive movie and TV-themed collections. If you are a fan of these themes, you'll find Social Kongs to be a fun and exciting collection.",
      },
      {
        title: "What is the pricing model for the Social Kongs NFT Collection ?",
        text: "Initially, the first 3,000 Social Kongs NFT will be offered for free with a limit of one purchase per wallet. After the initial 3,000 have been sold, the retail price for each NFT will be set at 0.02 ETH. Keep in mind that special and exclusive limited edition collections may have different pricing. Stay tuned for announcements regarding the release of these limited edition collections.",
      },
      {
        title: "What benefits will owners of Social Kongs NFTs have ?",
        text: "Owners of Social Kongs NFTs will have access to exclusive events and offers, including early access to new collections and merchandise. They will also have the opportunity to participate in voting and decision-making processes regarding the future development of the collection. In addition, they will receive special recognition and status as part of the Social Kongs community, including being featured on the official Social Kongs website and social media channels.",
      },
      {
        title: "How many Social Kongs NFT will be minted in total ?",
        text: "Initially, 10,000 Social Kongs NFT will be minted. However, more will be released through special exclusive and limited editions.",
      },
      {
        title: "What is the purpose of the Social Kongs NFT Collection ?",
        text: "The purpose of the Social Kongs NFT Collection is to bring a lighthearted and humorous take on the human obsession with social media, while also providing an engaging and entertaining collectible experience.",
      },
    ],
  },
  {
    category: "Staking",
    items: [
      {
        title: "What is MiNFT ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "How we can buy and invest NFT ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
      {
        title: "Why we should choose MiNFT ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "Where we can buy and sell NFts ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
      {
        title: "How secure is this token ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "What is your contract address ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
    ],
  },
  {
    category: "Community",
    items: [
      {
        title: "Is there a presele spot ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "How will you give back ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
      {
        title: "What is your contract address ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "How do I get on Whitelist ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
    ],
  },
  {
    category: "Roadmaps",
    items: [
      {
        title: "Is there a presele spot ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "How will you give back ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
      {
        title: "What is your contract address ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "How do I get on Whitelist ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
    ],
  },
  {
    category: "Metaverse",
    items: [
      {
        title: "What is your contract address ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "What is MiNFT ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "How we can buy and invest NFT ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
      {
        title: "Why we should choose MiNFT ?",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      },
      {
        title: "Where we can buy and sell NFts ?",
        text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
      },
    ],
  },
];

export default data;
