import { useState } from "react";
import { FiMinus } from "react-icons/fi";
import FilterBoxStyleWrapper from "./FilterBox.style";

const FilterBox = ({ widgetTitle, items, onCategoryChange, isSelected, filteredProductCount, products }) => {
  const [collapse, isCollapse] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleCollapse = () => {
    isCollapse(!collapse);
  };

  const handleFilterChange = (event) => {
    const filter = event.target.value;
    const index = selectedFilters.indexOf(filter);
    let updatedFilters;

    if (index === -1) {
      updatedFilters = [...selectedFilters, filter];
      onCategoryChange(filter, true);
    } else {
      updatedFilters = [
        ...selectedFilters.slice(0, index),
        ...selectedFilters.slice(index + 1),
      ];
      onCategoryChange(filter, false);
    }

    setSelectedFilters(updatedFilters);
  };
  
  const getProductCountForCategory = (categoryTitle) => {
    return products.filter((product) =>
      product.categories && product.categories.includes(categoryTitle)
    ).length;
  };

  const getFilteredProductCount = (category) => {
    if (selectedFilters.length === 0) {
      return category.products;
    }
  
    const isCategorySelected = selectedFilters.includes(category.title);
  
    if (!isCategorySelected) {
      return category.products;
    }
  
    return products.filter(
      (product) =>
        product.categories &&
        product.categories.includes(category.title)
    ).length;
  };

  const filteredItems =
    selectedFilters.length > 0
      ? items.filter((item) => selectedFilters.includes(item.title))
      : items;

      return (
        <FilterBoxStyleWrapper
          className={`filter_box_wrapper ${
            collapse ? "filter_box_collapse" : ""
          }`}
        >
          <div className="box_header">
            <button onClick={handleCollapse}>
              {widgetTitle} <FiMinus />
            </button>
          </div>

          <div className="box_items">
            {items?.map((item, idx) => {
              const filteredProductCount = getFilteredProductCount(item);
              return (
                <div key={idx} className="filter_checkbox">
                  <label className="checkbox_container">
                    {item.title} <span>({getProductCountForCategory(item.title)})</span>
                    <input
                      type="checkbox"
                      value={item.title}
                      onChange={handleFilterChange}
                      checked={selectedFilters.includes(item.title)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              );
            })}
          </div>
        </FilterBoxStyleWrapper>
      );
};

export default FilterBox;