import SectionTitle from "../../../../common/sectionTitle";
import Counter from "../../../../common/counter";

import aboutImageLeft from "../../../../assets/images/nft/about_image_left.png";
import aboutImageRight from "../../../../assets/images/nft/about_image_right.png";
import AboutInfoCardList from "../aboutInfoCardList";
import AboutStyleWrapper from "./About.style";

import data from "../../../../assets/data/about/aboutv1";

const About = () => {
  const { aboutDescription1, aboutDescription2 } = data;
  return (
    <AboutStyleWrapper className="v2_about_us_section" id="about">
      <div className="v2_about_overlay"></div>
      <div className="container">
        <SectionTitle
          className="text-center"
          isCenter={true}
          title="ABOUT SOCIAL KONGS"
          subtitle="THE MAIN 10K NFTs COLLECTION"
        />
        <div className="v2_about_us_content">
          <div className="v2_about_us_text">
            <p>{aboutDescription1}</p>
            <p>{aboutDescription2}</p>
            {/* <div className="counter-wrap">
              <h4>
                To be minted <Counter end="9999" />{" "}
              </h4>
              <h4>
                Attributes <Counter end="555" />
              </h4>
            </div> */}
          </div>
          <div className="v2_about_img v2_about_img_left">
            <span>
              <img src={aboutImageLeft} alt="bithu nft about" />
            </span>
          </div>
          <div className="v2_about_img v2_about_img_right">
            <span>
              <img src={aboutImageRight} alt="bithu nft thumb" />
            </span>
          </div>
        </div>

        {/* about card */}
        <AboutInfoCardList />
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
