import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import PaginationStyleWrapper from "./Pagination.style";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = Array.from(Array(totalPages).keys()).map((i) => i + 1);

  return (
    <PaginationStyleWrapper className="pagination_wrapper">
      <a
        href="# "
        onClick={(e) => {
          e.preventDefault();
          if (currentPage > 1) {
            onPageChange(currentPage - 1);
          }
        }}
        className={currentPage === 1 ? "disabled" : ""}
      >
        <FiChevronLeft />
      </a>

      {pageNumbers.map((pageNumber) => (
        <a
          key={pageNumber}
          href="# "
          className={pageNumber === currentPage ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            onPageChange(pageNumber);
          }}
        >
          {pageNumber}
        </a>
      ))}

      <a
        href="# "
        onClick={(e) => {
          e.preventDefault();
          if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
          }
        }}
        className={currentPage === totalPages ? "disabled" : ""}
      >
        <FiChevronRight />
      </a>
    </PaginationStyleWrapper>
  );
};

export default Pagination;
